import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="單人部落 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "本計畫是對當代居住性一種描繪的嘗試，試圖從對當代生活中「一人成為聚落如何可能」出發，啟動對關係與連結的想像。",
            },
            {
              property: `og:title`,
              content: "單人部落 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            // {
            //   property: `og:description`,
            //   content: "本計畫是對當代居住性一種描繪的嘗試，試圖從對當代生活中「一人成為聚落如何可能」出發，啟動對關係與連結的想像。",
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/blog/post_1_10.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;